import { useDarkMode } from 'usehooks-ts';
import { Image } from '@/components/common/Image/Image';
import backgroundDark from '@/components/pages/auth/SignIn/assets/images/login-bg--dark.svg';
import backgroundLight from '@/components/pages/auth/SignIn/assets/images/login-bg--light.svg';
import type { AuthContainerProps } from '@/components/pages/auth/ui/AuthContainer/AuthContainer.types';
import { renderClasses } from '@/utils/renderClassses';

export const AuthContainer = ({ children, isBackground = false }: AuthContainerProps) => {
  const { isDarkMode } = useDarkMode();

  return (
    <div
      className={'min-h-svh w-screen flex flex-col justify-center items-center relative'}
    >
      {isBackground && (
        <>
          <Image
            src={isDarkMode ? backgroundDark : backgroundLight}
            className={'hidden lg:block! lg:visible! absolute inset-0 object-contain size-full object-[left_top] xl:object-[center_top]'}
          />
          <div className={'hidden lg:block! lg:visible! bg-black/60 dark:bg-gray-900 opacity-60 inset-0 absolute'} />
        </>
      )}
      <div
        className={renderClasses('sm:max-w-[36rem] w-full p-24 flex flex-col gap-24 relative z-10 rounded-lg', {
          'lg:bg-white lg:border lg:border-gray-200 lg:dark:bg-gray-900/60 dark:lg:border-gray-700 lg:m-16': isBackground,
        })}
      >
        {children}
      </div>
    </div>
  );
};
