import { Heading } from '@/components/common/Heading/Heading';
import { Image } from '@/components/common/Image/Image';
import { Paragraph } from '@/components/common/Paragraph/Paragraph';
import type { AuthPageHeaderProps } from '@/components/pages/auth/ui/AuthPageHeader/AuthPageHeader.types';

export const AuthPageHeader = ({ title, logo, description }: AuthPageHeaderProps) => (
  <div className={'text-center flex flex-col gap-24 items-center'}>
    {logo && <Image src={logo} className={'h-56'} />}
    <Heading variant={'h1'} className={'font-semibold text-[2.4rem]! m-0! p-0! whitespace-break-spaces'}>{title}</Heading>
    {description && <Paragraph className={'text-base! m-0! leading-[2.7rem] whitespace-break-spaces'}>{description}</Paragraph>}
  </div>
);
